<template>
  <a-modal
    title="身份二维码"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelForm"
    :footer="null"
  >
    <div style="text-align: center">
      <a-spin :spinning="loading">
        <div v-for="item in codeList" :key="item.keyId">
          <div>{{ item.areaCodeName }} {{ item.communityName }} 身份码</div>
          <div>
            <img :src="item.codeUrl" width="150" />
          </div>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>
<script>
import { getUserCode } from '@/api/user'
export default {
  name: 'ClassifyInfo',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      queryParam: {
        userId: ''
      },
      codeInfo: {
        codeUrl: ''
      },
      codeList: []
    }
  },
  created () {
  },
  methods: {
    ininData (ownerId) {
      console.log('ininData', ownerId)
      this.queryParam.userId = ownerId
      this.saveDataApi()
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      _this.loading = true
      getUserCode(_this.queryParam).then((res) => {
        _this.codeList = res.result.codeList
        _this.loading = false
        console.log(res)
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
